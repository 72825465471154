<template>
    <v-main class="main-bg-aloe">
        <div
            class="cookie-notification"
            v-if="cookieShow"
        >
            <p>
                Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
                <router-link :to="{name: 'Cookies'}" target="_blank">
                    файлах cookie
                </router-link>
            </p>
            <v-btn
                color="gray"
                class="cookie-accept"
                @click="cookieAccept"
            >
                Хорошо
            </v-btn>
        </div>

        <div class="aloe-main-block">
            <section class="main-section">
              <img src="/img/aloe/aloe-bg-main-left-top.png" alt="" class="main-bg-aloe-left-top" />
              <h2 class="h2-main">
                ТВОЙ ЭЛИКСИР ЖИЗНИ ДЛЯ ЛУЧШЕЙ ВЕРСИИ СЕБЯ
              </h2>
              <v-divider class="h2-border"></v-divider>
              <div class="video-main-box">
                <div class="aloe-video">
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                    <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                      <iframe 
                        src="https://fast.wistia.net/embed/iframe/spr7j2i7pn?seo=false&videoFoam=true" 
                        title="LR Алоэ Верa – Ваш эликсир жизни для лучшего самочувствия Video" 
                        allow="autoplay; fullscreen" 
                        allowtransparency="true" 
                        frameborder="0" 
                        scrolling="no" 
                        class="wistia_embed" 
                        name="wistia_embed" 
                        msallowfullscreen 
                        width="100%" 
                        height="100%">
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div style="text-align: center;" class="mt-7">
                <button @click="scrollToForm()"><img src="/img/aloe/aloe-btn-more.png" alt=""></button>
              </div>
              <img src="/img/aloe/aloe-bg-main-right-bottom.png" alt="" class="main-bg-aloe-right-bottom" />
            </section>
            <section class="health-section">
              <h2 class="h2-aloe">
                АЛОЭ - РАСТЕНИЕ БЕССМЕРТИЯ
              </h2>
              <v-divider class="h2-border"></v-divider>
              <img src="/img/aloe/aloe-bg-main-right.png" alt="" class="health-bg-aloe-right" />
              <h3 class="h3-aloe-health">Питьевые гели LR на его основе дадут тебе:</h3>
              <v-row>
                <v-col cols="12" sm="6">
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    улучшение работы жкт и перистальтики
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    регулирование веса и давления
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    умственную и физическую работоспособность
                  </p>                     
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    укрепление иммунной системы
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    оптимизацию сахара в крови
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    замедление процессов старения
                  </p>
                </v-col>
                <v-col cols="12" sm="6" class="health-col-bottom">
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    суточную дозу витаминов и минералов
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    избавление от шлаков и токсинов
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    нормализацию уровня холестерина
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    снижение воспалительных процессов
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    улучшение усвоения микронутриентов
                  </p>
                  <p class="p-li-aloe">
                    <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                    нормализацию обмена веществ
                  </p>
                </v-col>
              </v-row>
              <h3 class="h3-aloe">
                УЛУЧШИ СВОЕ ЗДОРОВЬЕ ГЛОТОК ЗА ГЛОТКОМ С ГЕЛЯМИ <span>LR HEALTH & BEAUTY</span>
              </h3>
              <div style="text-align: center;">
                <button @click="scrollToForm()"><img src="/img/aloe/aloe-btn-health.png" alt=""></button>
              </div>
            </section>

            <section class="history-section">
                <h2 class="h2-aloe">
                  ИСТОРИЯ ИСПОЛЬЗОВАНИЯ АЛОЭ В ЛЕЧЕБНЫХ ЦЕЛЯХ УХОДИТ НА ТЫСЯЧИ ЛЕТ НАЗАД
                  <img src="/img/aloe/aloe-bg-history-left.png" alt="" class="history-bg-aloe-left" />
                </h2>
                <v-divider class="h2-border"></v-divider>
                <div class="video-box">
                  <div class="aloe-video">
                    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                      <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                        <iframe
                          src="https://fast.wistia.net/embed/iframe/i27jmdmjbs?seo=false&videoFoam=true" 
                          title="video_2023-07-31_14-16-42" 
                          allow="autoplay; fullscreen" 
                          allowtransparency="true" 
                          frameborder="0" 
                          scrolling="no" 
                          class="wistia_embed" 
                          name="wistia_embed" 
                          msallowfullscreen 
                          width="100%" 
                          height="100%">
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class="h4-aloe-person">
                  Известные исторические личности регулярно применяли это растение:
                </h4>
                <v-row>
                  <v-col cols="12" sm="3">
                    <div class="person-img">
                      <img src="/img/aloe/aloe-columb.png" alt="" />
                    </div>
                    <p class="p-person">
                      Христофор <br />
                      Колумб
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="person-img">
                      <img src="/img/aloe/aloe-alex.png" alt="" />
                    </div>
                    <p class="p-person">
                      Александр <br />
                      Македонский
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="person-img">
                      <img src="/img/aloe/aloe-qleopatra.png" alt="" />
                    </div>
                    <p class="p-person">
                      Клеопатра
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="person-img">
                      <img src="/img/aloe/aloe-pifagor.png" alt="" />
                    </div>
                    <p class="p-person">
                      Пифагор
                    </p>
                  </v-col>
                </v-row>
                <v-row style="position: relative;">
                  <v-col cols="12" sm="3">
                    <div class="history-img">
                      <img src="/img/aloe/aloe-egipt.jpg" alt="" />
                    </div>
                    <h4 class="h4-aloe">ДРЕВНИЙ ЕГИПЕТ</h4>
                    <div class="history-item-text">
                      <p class="p-history">Упоминание 16 век до н.э.</p>
                      <p class="p-history">Использование:</p>
                      <ul class="ul-aloe-history">
                        <li>в косметических и медицинских целях</li>
                        <li>для лечения ран</li>
                        <li>ожогов</li>
                        <li>заболеваний кожи</li>
                        <li>проблем с жкт</li>
                      </ul>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="history-img">
                      <img src="/img/aloe/aloe-india.jpg" alt="" />
                    </div>
                    <h4 class="h4-aloe">ИНДИЯ</h4>
                    <div class="history-item-text">
                      <p class="p-history">Упоминание 6-4 век до н.э.</p>
                      <p class="p-history">Использование:</p>
                      <ul class="ul-aloe-history">
                        <li>
                          в аюрведической медицине, которой более 5000 лет
                        </li>
                        <li>
                          для лечения широкого спектра заболеваний, включая проблемы с пищеварением,  воспалениями и ранами
                        </li>
                      </ul>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="history-img">
                      <img src="/img/aloe/aloe-rome.jpg" alt="" />
                    </div>
                    <h4 class="h4-aloe">ДРЕВНЯЯ ГРЕЦИЯ И РИМ</h4>
                    <div class="history-item-text">
                      <p class="p-history">Упоминания 6 век до н.э.</p>
                      <p class="p-history">Использование:</p>
                      <ul class="ul-aloe-history">
                        <li>для лечения ран</li>
                        <li>ожогов</li>
                        <li>кожных проблем</li>
                        <li>внутренних заболеваний</li>
                        <li>при зубных болях</li>
                      </ul>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="history-img">
                      <img src="/img/aloe/aloe-china.jpg" alt="" />
                    </div>
                    <h4 class="h4-aloe">КИТАЙ</h4>
                    <div class="history-item-text">
                      <p class="p-history">Упоминание 3-1 век до н.э.</p>
                      <p class="p-history">Использование:</p>
                      <ul class="ul-aloe-history">
                        <li>
                          проблемы с пищеварением
                        </li>
                        <li>
                          кровообращением
                        </li>
                        <li>воспалениями и кожными проблемами</li>
                      </ul>
                    </div>
                  </v-col>
                  <img src="/img/aloe/aloe-bg-history-right-1.png" alt="" class="history-aloe-bg-right-bottom">
                </v-row>
                <h3 class="h3-aloe">
                  ИСПОЛЬЗУЙ ЕСТЕСТВЕННОЕ ОЧИЩЕНИЕ ОРГАНИЗМА НА КЛЕТОЧНОМ УРОВНЕ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()"><img src="/img/aloe/aloe-btn-clear.png" alt=""></button>
                </div>
            </section>
            <section class="since-section">
                <h2 class="h2-aloe">
                  ФАНТАСТИЧЕСКИЙ ЭФФЕКТ, <br />
                  ОСНОВАННЫЙ НА НАУЧНЫХ ФАКТАХ
                  <img src="/img/aloe/aloe-bg-since-left.png" alt="" class="since-bg-aloe-left">
                </h2>
                <v-divider class="h2-border"></v-divider>
                <p class="p-since">Алоэ самое изученное и полезное растение в мире, его польза доказана сотнями научных исследований.</p>
                <div class="video-box">
                  <div class="aloe-video">
                    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                      <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                        <iframe 
                          src="https://fast.wistia.net/embed/iframe/5vyyxnjz8u?seo=false&videoFoam=true" 
                          title="Новый проект на сайт Video" 
                          allow="autoplay; fullscreen" 
                          allowtransparency="true" 
                          frameborder="0" 
                          scrolling="no" 
                          class="wistia_embed" 
                          name="wistia_embed" 
                          msallowfullscreen 
                          width="100%" 
                          height="100%">
                        </iframe>
                      </div>
                    </div>
                  </div>
                  <img src="/img/aloe/aloe-bg-since-video.png" alt="" class="since-bg-aloe-right">
                </div>
                <p class="p-title-since">
                  Гель алоэ LR Health & Beauty содержит более 200 биологически активных соединений:
                </p>
                <v-row class="since-item">
                  <v-col cols="12" sm="3" class="flex-col">
                    <img src="/img/aloe/aloe-polysahorid.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="9" class="flex-col">
                    <p>
                      Ацеманнан - полисахарид, обладает иммуномодулирулирующим, противовирусным, противоопухолевыми свойствами.
                      Улучшает усвояемость микронутриентов. Способствует заживлению ран и слизистых.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="since-item">
                  <v-col cols="12" sm="3" class="flex-col">
                    <img src="/img/aloe/aloe-innulin.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="9" class="flex-col">
                    <p>
                      Инулин - естественный пребиотик, улучшает перистальтику кишечника, стимулирует пищеварение,
                      обеспечивает питание и рост бифидобактерий, обладает гепатопротекторными свойствами.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="since-item">
                  <v-col cols="12" sm="3" class="flex-col">
                    <img src="/img/aloe/aloe-anzim.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="9" class="flex-col">
                    <p>
                      Энзимы - помогают разлагать пищу и усваивать питательные вещества, а также оказывают противовоспалительное действие.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="since-item">
                  <v-col cols="12" sm="3" class="flex-col">
                    <img src="/img/aloe/aloe-antioxidant.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="9" class="flex-col">
                    <p>
                      Полифенолы, флавоноиды и другие антиоксиданты - защищают клетки организма от вредного воздействия свободных радикалов и
                      способствуют замедлению процессов старения.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="since-item">
                  <v-col cols="12" sm="3" class="flex-col">
                    <img src="/img/aloe/aloe-fitosterol.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="9" class="flex-col">
                    <p>
                      Фитостеролы - натуральные компоненты мембран клеток растений, контролируют уровень холестерина,
                      нормализуют работу пищеварительной системы. Снижают воспаление и укрепляют иммунную систему.
                    </p>
                  </v-col>                  
                </v-row>
                <p class="p-title-since zaeb">
                  Аминокислоты, витамины, минералы и ферменты в гелях Алоэ Вера образуют особую комбинацию активных ингредиентов,
                  которая благотворно влияет на весь организм, нормализуя все внутренние процессы.
                </p>
                <h3 class="h3-aloe">
                  ОТКРОЙ ДЛЯ СЕБЯ СЕКРЕТ МОЛОДОСТИ И ДОЛГОЛЕТИЯ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()"><img src="/img/aloe/aloe-btn-beauty.png" alt=""></button>
                </div>
            </section>

            <section class="gel-section">
              <h2 class="h2-aloe">
                ДОБРО ПОЖАЛОВАТЬ В РАЗНООБРАЗИЕ ВКУСНОГО МИР ПИТЬЕВЫХ ГЕЛЕЙ <span>LR HEALTH & BEAUTY</span>
                <img src="/img/aloe/aloe-bg-gel-right.png" alt="" class="gel-bg-aloe-right">
              </h2>
              <v-divider class="h2-border"></v-divider>
                <p class="p-title-gel">
                  В каждый гель мы добавили природные компоненты усиливающие его полезные свойства:
                </p>
                <v-row class="gel-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-peach.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="7" class="flex-col">
                    <p>
                      Персик (peach) - правильное начало знакомства с гелями. Очищает организм и улучшает работу жкт
                      и всех процессов в организме. Твой выбор для коррекции веса и гармонии со своим телом.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3" class="flex-col">
                    <button @click.stop="dialog_peach = true">Подробнее</button>
                  </v-col>
                </v-row>
                <v-row class="gel-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-sivera.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="7" class="flex-col">
                    <p>
                      Сивера (sivera) - с крапивой. Нормализация давления, укрепление сосудов и всей кровеносной системы.
                      Для здоровья твоего сердечно сосудистого аппарата.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3" class="flex-col">
                      <button @click.stop="dialog_sivera = true">Подробнее</button>
                  </v-col>
                </v-row>
                <v-row class="gel-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-freedom.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="7" class="flex-col">
                    <p>
                      Фридом (freedom) - с хондроитином, глюкозамином и коллагеном. Снимает воспаление, укрепляет и восстанавливает связки и суставы.
                      Улучшает эластичность и качество кожи. Для спортсменов и поддержки опорно-костного аппарата.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3" class="flex-col">
                      <button @click.stop="dialog_freedom = true">Подробнее</button>
                  </v-col>
                </v-row>
                <v-row class="gel-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-immun.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="7" class="flex-col">
                    <p>
                      Иммун (immune) - c имбирем, медом, лимоном, гингеролом и цинком. Модулирует и усиливает иммунитет, защищает от бактерий и вирусов.
                      Противовоспалительное и антиоксидантное действие. Твоя защита в период обострения инфекций и эпидемий.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3" class="flex-col">
                      <button @click.stop="dialog_immun = true">Подробнее</button>
                  </v-col>
                </v-row>
                <v-row class="gel-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-asai.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="7" class="flex-col">
                    <p>
                      Асаи (aсai) - c ягодой асаи и черникой. Защита от ультрафиолета, стресса и обезвоживания летом.
                      Иммунитет всего организма и улучшения репродуктивных функций. Для твоего идеального и солнечного отпуска.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3" class="flex-col">
                      <button @click.stop="dialog_asai = true">Подробнее</button>
                  </v-col>
                </v-row>
                <v-row class="gel-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-honey.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="7" class="flex-col">
                    <p>
                      Мед (honey) - с медом. Обновление клеток и поддержка иммунной системы. Важные питательные вещества и улучшение энергетического обмена.
                      Нормализация аппетита и повышение работоспособности. Для твоего активного образа жизни круглый год.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3" class="flex-col">
                      <button @click.stop="dialog_honey = true">Подробнее</button>
                  </v-col>
                </v-row>
                <h3 class="h3-aloe">
                  ВЫБЕРИ СВОЙ ВКУС И ПРОБУДИ СВОЮ ЖИЗНЕННУЮ ЭНЕРГИЮ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()"><img src="/img/aloe/aloe-btn-energy.png" alt=""></button>
                </div>
                <img src="/img/aloe/aloe-bg-gel-left-top.png" alt="" class="gel-aloe-bg-left">
            </section>

            <section class="lr-section">
                <h2 class="h2-aloe">
                  ПИТЬЕВЫЕ ГЕЛИ LR HEALTH & BEAUTY - ГЛАВНЫЙ ИНГРЕДИЕНТ ТВОЕГО ЗДОРОВЬЯ И БЛАГОПОЛУЧИЯ
                </h2>
                <v-divider class="h2-border"></v-divider>
                <div class="video-box-2">
                  <div class="aloe-video">
                    <div class="wistia_responsive_padding" style="padding:41.88% 0 0 0;position:relative;">
                      <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                        <iframe 
                          src="https://fast.wistia.net/embed/iframe/f2iu1t92ao?seo=false&videoFoam=true" 
                          title="Алоэ Вера 20 лет Video" 
                          allow="autoplay; fullscreen" 
                          allowtransparency="true" 
                          frameborder="0" 
                          scrolling="no" 
                          class="wistia_embed" 
                          name="wistia_embed" 
                          msallowfullscreen 
                          width="100%" 
                          height="100%">
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 class="h2-aloe">
                  ГАРАНТИИ КАЧЕСТВА
                </h2>
                <v-row class="certificate-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-bg-main-right.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="10" class="flex-col">
                    <p>
                      Для производства гелей Алоэ Вера LR используется самый ценный и богатый микронутриентами вид – Aloe barbadensis Miller.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="certificate-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-certified.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="10" class="flex-col">
                    <p>
                      Знак качества Международного научного совета по Алоэ (IASC).
                      Что подтверждает бережную обработку сырья и высокое содержание геля Алоэ Вера без алоина.
                    </p>
                </v-col>
                </v-row>
                <v-row class="certificate-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/aloe/aloe-fresenius.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="10" class="flex-col">
                    <p>
                      Сертификат Института Фрезениуса (SGS INSTITUT FRESENIUS).
                      Что означает независимую регулярную проверку производства и качество продукции на всех этапах:
                      от сбора сырья, до характеристик готового продукта.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mt-10">
                  <v-col cols="12" sm="6">
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Свой научный совет и консультации специалистов
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Большой выбор вкусов и спецификаций
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Знак качества международного совета алоэ
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Инновации и современные технологии
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Уникальные запатентованные формулы
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      35 лет немецкого качества
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" class="certified-col-bottom">
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Доставка до дома и возможность онлайн заказов
                    </p>                    
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Самая большая концентрация геля Алоэ до 98%
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Экологически чистое производство
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Сертификат института фрезениуса
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Лидер продаж продуктов алое в Евразии
                    </p>
                    <p class="p-li-aloe">
                      <img src="/img/aloe/aloe-check.png" alt="" class="li-check" />
                      Более 200 биологически активных соединений
                    </p>
                  </v-col>
                </v-row>
                <h3 class="h3-aloe">
                  ЗАБОТА С ЛЮБОВЬЮ - ПОДАРИ СЕБЕ ПРИРОДНЫЙ ЭЛИКСИР ЗДОРОВЬЯ И ДОЛГОЛЕТИЯ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()"><img src="/img/aloe/aloe-btn-buy.png" alt=""></button>
                </div>
            </section>

            <section class="reviews-section">
                <h3 class="h3-title-reviews">
                  Но наша главная гордость - Положительные результаты и отзывы сотен тысяч клиентов:
                </h3>
                <v-carousel
                  class="aloe-carousel"
                  cycle
                  height="400"
                  hide-delimiter-background
                  hide-delimiters
                  show-arrows-on-hover
                >
                  <v-carousel-item
                      v-for="(slide, i) in slides"
                      :key="i"
                  >
                    <v-sheet
                      height="100%"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="text-h2">
                          <p style="width: 100%; max-width: 450px; height: auto">
                              <img style="width: 100%;" :src=slide.src alt=""/>
                          </p>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
                <img src="/img/aloe/aloe-bg-since-video.png" alt="" class="aloe-bg-reviews-bottom-right">
            </section>

            <section v-if="userData.id != 127" class="form-section" id="aloe-form">
                <v-container class="text-center">
                    <h2 class="h2-aloe">
                      СДЕЛАЙ ЗАБОТУ О СЕБЕ ПРАВИЛЬНОЙ ПРИВЫЧКОЙ С ПИТЬЕВЫМИ ГЕЛЯМИ <span>LR HEALTH & BEAUTY</span>
                    </h2>
                    <v-divider class="h2-border"></v-divider>
                    <p class="p-subtitle-form">
                      Оставь заявку и получи бесплатную консультацию по подбору питьевых гелей именно для тебя:
                    </p>
                    <v-responsive
                        class="mx-auto mb-10"
                        max-width="600px"
                    >
                        <v-text-field
                            label="Ваше имя"
                            prepend-icon="mdi-face-man"
                            type="text"
                            v-model.trim="newProspect.name"
                            :error-messages="nameErrors"
                            @input="$v.newProspect.name.$touch()"
                            @blur="$v.newProspect.name.$touch()"
                        />
                        <v-text-field
                            class="cell-input-phone"
                            label="Телефон"
                            prepend-icon="mdi-phone"
                            type="text"
                            v-model.trim="newProspect.phone"
                            :error-messages="phoneErrors"
                            @input="$v.newProspect.phone.$touch()"
                            @blur="$v.newProspect.phone.$touch()"
                        />
                        <div class="invalid-feedback" v-if="errors.phone">
                            {{ errors.phone[0] }}
                        </div>

                        <p style="text-align: center">
                            <button
                                class="mr-0"
                                @click="addProspectAloe"
                            >
                                <img src="/img/aloe/aloe-btn-send.png" alt="">
                            </button>
                        </p>
                        <p class="aroma-privacy-text">
                            Оставляя заявку, вы даете свое
                            <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                            и принимаете
                            <router-link :to="{name: 'Policy'}" target="_blank">Политику конфиденциальности</router-link>.
                        </p>
                    </v-responsive>
                    <h2 class="h2-aloe mt-n15">
                      ПОЗВОЛЬ ПИТЬЕВЫМ ГЕЛЯМ АЛОЭ СТАТЬ ТВОИМ ГЛАВНЫМ ПАРТНЕРОМ НА ПУТИ К ЗДОРОВЬЮ И БЛАГОПОЛУЧИЮ!
                    </h2>
                </v-container>                
            </section>

            <v-footer
                class="justify-center aloe-footer"
                height="100"
            >
                <div class="title font-weight-light text-center">
                    &copy; {{ (new Date()).getFullYear() }} — newlvl.net — независимый партнер LR Health & Beauty
                </div>
            </v-footer>
        </div>

        <v-dialog
            v-model="dialog_peach"
            max-width="320px"
        >
            <v-card class="dialog-card-peach">
              <div class="wistia_responsive_padding" style="padding:177.78% 0 0 0;position:relative;">
                <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                  <v-icon color="#333" class="icon-dialog" @click="dialog_peach = false">mdi-close-circle</v-icon>
                  <iframe src="https://fast.wistia.net/embed/iframe/a21nkzcgpo?seo=false&videoFoam=true" title="Гель Персик Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe>
                </div>
              </div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_sivera"
            max-width="320px"
        >
            <v-card class="dialog-card-sivera">
              <v-icon color="#333" class="icon-dialog" @click="dialog_sivera = false">mdi-close-circle</v-icon>
              <div class="wistia_responsive_padding" style="padding:177.78% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/0d5y2pbhvc?seo=false&videoFoam=true" title="Гель Сивера Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_honey"
            max-width="320px"
        >
            <v-card class="dialog-card-honey">
              <v-icon color="#333" class="icon-dialog" @click="dialog_honey = false">mdi-close-circle</v-icon>
              <div class="wistia_responsive_padding" style="padding:177.78% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/els2luwy2u?seo=false&videoFoam=true" title="Мед Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_freedom"
            max-width="320px"
        >
            <v-card class="dialog-card-freedom">
              <v-icon color="#333" class="icon-dialog" @click="dialog_freedom = false">mdi-close-circle</v-icon>
              <div class="wistia_responsive_padding" style="padding:177.78% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/2p3fnj7d7x?seo=false&videoFoam=true" title="Гель Фридом Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_immun"
            max-width="320px"
        >
            <v-card class="dialog-card-immun">
              <v-icon color="#333" class="icon-dialog" @click="dialog_immun = false">mdi-close-circle</v-icon>
              <div class="wistia_responsive_padding" style="padding:177.78% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/t1cxxkil5o?seo=true&videoFoam=true" title="Гель Иммун плюс Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_asai"
            max-width="320px"
        >
            <v-card class="dialog-card-asai">
              <v-icon color="#333" class="icon-dialog" @click="dialog_asai = false">mdi-close-circle</v-icon>
              <div class="wistia_responsive_padding" style="padding:177.78% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/hud4cg0pca?seo=true&videoFoam=true" title="Асаи Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_prospect"
            width="500"
        >
            <v-card>
                <v-card-title class="headline lighten-2 pb-5" style="background-color: #213e30; color: white">
                    Заявка принята
                </v-card-title>

                <v-card-text>
                    Твой консультант свяжется с тобой в ближайшее время
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#213e30"
                        text
                        @click="dialog_prospect = false"
                    >
                        Хорошо
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

export default {
    name: "AloeVera",
    data: () => ({
        newProspect: {
            name: '',
            phone: '',
            partner: '',
            instrument: 'AloeVera',
            step: 'Новый'
        },
        slides: [
            {
                src: '/img/aloe/otzivi/1.png',
            },
            {
                src: '/img/aloe/otzivi/2.png',
            },
            {
                src: '/img/aloe/otzivi/3.png',
            },
            {
                src: '/img/aloe/otzivi/4.png',
            },
            {
                src: '/img/aloe/otzivi/5.png',
            },
            {
                src: '/img/aloe/otzivi/6.png',
            },
            {
                src: '/img/aloe/otzivi/7.png',
            },
            {
                src: '/img/aloe/otzivi/8.png',
            },
            {
                src: '/img/aloe/otzivi/9.png',
            },
            {
                src: '/img/aloe/otzivi/10.png',
            },
            {
                src: '/img/aloe/otzivi/11.png',
            },
            {
                src: '/img/aloe/otzivi/12.png',
            },
            {
                src: '/img/aloe/otzivi/13.png',
            },
            {
                src: '/img/aloe/otzivi/14.png',
            },
            {
                src: '/img/aloe/otzivi/15.png',
            },
            {
                src: '/img/aloe/otzivi/16.png',
            },
            {
                src: '/img/aloe/otzivi/17.png',
            },
            {
                src: '/img/aloe/otzivi/18.png',
            },
            {
                src: '/img/aloe/otzivi/19.png',
            },
            {
                src: '/img/aloe/otzivi/20.png',
            },
            {
                src: '/img/aloe/otzivi/21.png',
            },
        ],
        errors: {},
        dialog_main: false,
        dialog_prospect: false,
        dialog_peach: false,
        dialog_sivera: false,
        dialog_honey: false,
        dialog_freedom: false,
        dialog_immun: false,
        dialog_asai: false,
        loading: false,
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Гели Aloe Vera от LR',
        meta: [
            { vmid: 'description', property: 'description', content: 'Гели Aloe Vera от LR' },
            { vmid: 'og:title', property: 'og:title', content: 'Гели Aloe Vera от LR' },
            { vmid: 'og:description', property: 'og:description', content: 'Гели Aloe Vera от LR.' },
            { vmid: 'og:image', property: 'og:image', content: '/img/aloe/aloe-vera.png' },
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push ({name: 'AloeVera', query: { partner: partnerNum }})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }

        if (this.$cookies.isKey('newlvl_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUser'
        }),
        dataUser() {
            return this.userData
        },
        nameErrors() {
            const errors = []
            if (!this.$v.newProspect.name.$dirty) return errors
            !this.$v.newProspect.name.minLength && errors.push('Минимум 2 буквы')
            !this.$v.newProspect.name.maxLength && errors.push('Максимум 30 букв')
            !this.$v.newProspect.name.required && errors.push('Обязательно для заполнения')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.newProspect.phone.$dirty) return errors
            !this.$v.newProspect.phone.numeric && errors.push('Только цифры')
            !this.$v.newProspect.phone.minLength && errors.push('Минимум 5 цифр')
            !this.$v.newProspect.phone.maxLength && errors.push('Максимум 30 цифр')
            !this.$v.newProspect.phone.required && errors.push('Обязательно для заполнения')
            return errors
        }
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showUser: 'user/showUserPage',
            createProspect: 'user/addClient'
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('newlvl_cookie', 'accept', '12m')
        },
        scrollToForm() {
            let scrollBottom = document.getElementById("aloe-form")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        addProspectAloe() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading = true
                this.createProspect(this.newProspect)
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loading = false
                            this.dialog_prospect = true
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            case 500:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
    },
    mounted () {
        this.showUser({user: this.$route.query.partner, inst: this.$route.query.inst})
        this.newProspect.partner = this.$route.query.partner
        this.newProspect.inst = this.$route.query.inst
    },
    validations: {
        newProspect: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            phone: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(30)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: Backwards;
  src: url('/fonts/Backwards-Regular.otf');  
}
@font-face {
  font-family: Commons-pro;
  src: url('/fonts/Commons-Regular.woff');
}
.main-bg-aloe {
  background-color: #ECF1E8;
  font-family: Commons-pro;
  // color: #211D1E;
  color: #545454;
}
h1, h2, h3, h4 {
  color: #275601;
  font-family: Backwards;
  letter-spacing: 3px;
  font-weight: 500;
}
.aloe-main-block {  
  overflow-x: hidden;
}
section {
  max-width: 1280px;
  margin: 0 auto;
  padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
}
.main-section {
  position: relative;
  z-index: 1;
}
.main-bg-aloe-left-top {
  max-width: 280px;
  width: calc(150px + (280 - 150) * ((100vw - 320px) / (1280 - 320)));
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
}
.h2-main {
  text-align: center;
  font-size: calc(18px + (48 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 110%;
  padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
}
.h2-aloe {
  position: relative;
  text-align: center;
  font-size: calc(18px + (48 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 110%;
  padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
  z-index: 1;
}
.h2-border {
  border-color: #333;
  width: 90%;
  margin: 0 auto;
  margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.video-main-box {
  max-width: 800px;
  margin: 0 auto;
  background: url("/img/aloe/aloe-video-bg-1.png");
  padding: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}
.video-box {
  max-width: 800px;
  margin: 0 auto;
  background: url("/img/aloe/aloe-video-bg-2.png");
  padding: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.video-box-2 {
  max-width: 800px;
  margin: 0 auto;
  background: url("/img/aloe/aloe-video-bg-3.png");
  padding: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.aloe-video {
  // max-width: 720px;
}
.main-bg-aloe-right-bottom {
  max-width: 170px;
  width: calc(80px + (170 - 80) * ((100vw - 320px) / (1280 - 320)));
  opacity: 0.8;
  position: absolute;
  bottom: 7%;
  right: 0;
  z-index: -1;
}
.health-section,
.history-section,
.since-section,
.gel-section,
.lr-section,
.reviews-section {
  position: relative;
  z-index: 1;
}
.health-bg-aloe-right {
  max-width: 170px;
  width: calc(80px + (170 - 80) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  top: 7%;
  right: 0;
}
.h3-aloe {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  line-height: 110%;
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.h3-aloe-health {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  // padding-top: calc(10px + (40 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 2px;
}
.h3-title-reviews {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 2px;
  text-align: center;
}
.aloe-carousel .theme--light.v-sheet {
  background-color: #ECF1E8;
}
.li-check {
  width: 25px;
  position: absolute;
  left: 0;
}
.p-li-aloe {
  font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));
  margin-bottom: 10px;
  position: relative;
  padding-left: 35px;
}
.history-bg-aloe-left {
  max-width: 270px;
  width: calc(90px + (270 - 90) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: -40px;
  left: 0;
}
.aloe-bg-reviews-bottom-right {
  max-width: 270px;
  width: calc(130px + (270 - 130) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: -40px;
  right: 0;
}
.since-bg-aloe-left {
  max-width: 270px;
  width: calc(80px + (270 - 80) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: -40px;
  left: 0;
  z-index: -1;
}
.since-bg-aloe-right {
  max-width: 270px;
  width: calc(130px + (270 - 130) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: -40px;
  right: -140px;
  z-index: -1;
}
.gel-bg-aloe-right {
  max-width: 180px;
  width: calc(130px + (180 - 130) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: -40px;
  right: -40px;
  z-index: -1;
}
.gel-aloe-bg-left {
  max-width: 180px;
  width: calc(100px + (180 - 100) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: -80px;
  left: 0;
  z-index: -1;
}
.history-aloe-bg-right-bottom {
  max-width: 270px;
  width: calc(130px + (270 - 130) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: 0px;
  right: 0;
}
.h4-aloe-person {
  color: #333;
  font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  text-align: center;
  padding-top: calc(10px + (40 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 1px;
}
.p-person {
  color: #333;
  font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  text-align: center;
  padding-top: calc(10px + (40 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 1px;
  font-family: Backwards;
}
.person-img, .history-img {
  text-align: center;
}
.h4-aloe {
  font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  text-align: center;
  padding-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 1px;
}
.p-history, .ul-aloe-history {
  font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  margin-bottom: 10px;
}
.ul-aloe-history li {
  margin-bottom: 10px;
}
.p-since {
  font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  margin-bottom: 10px;
  text-align: center;
  padding-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
}
.p-title-since, .p-title-gel, .p-subtitle-form {
  font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  margin-bottom: 10px;
  text-align: center;
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
}
.p-title-since.zaeb {
  font-weight: bold;
}
.dialog-card-peach, .dialog-card-sivera, .dialog-card-honey, .dialog-card-freedom, .dialog-card-asai, .dialog-card-immun {
  overflow: hidden;
  position: relative;
}
.icon-dialog {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.p-title-gel {
  padding-top: 10px;
}
.p-subtitle-form {
  padding-top: 0px;
}
.since-item p, .gel-item p, .certificate-item p {
  font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  padding-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
  margin-bottom: 0;
}
.gel-item button {
    border: 1px solid #275601;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1280 - 320)));
    padding: 9px 15px 7px;
    color: #275601;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
.flex-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gel-item img, .certificate-item img, .since-item img, .person-img img, .history-img {
  width: 100%;
}
.aloe-footer  {
  background-color: #ECF1E8;  
}

@media (min-width: 1280px) {
  .h2-main {
    font-size: 48px;
  }
  .h2-aloe {
    font-size: 48px;
  }
  .h3-aloe {
    font-size: 36px;
  }
  .h3-aloe-health {
    font-size: 46px;
  }
  .h3-aloe-reviews {
    font-size: 46px;
  }
  .p-li-aloe {
    font-size: 24px;
  }
  .h4-aloe-person, .p-person {
    font-size: 32px;
  }
  .h4-aloe {
    font-size: 24px;
  }
  .p-history, .ul-aloe-history {
    font-size: 22px;
  }
  .p-since {
    font-size: 26px;
  }
  .p-title-since, .p-title-gel, .p-subtitle-form {
    font-size: 26px;
  }
  .since-item p, .gel-item p, .certificate-item p {
    font-size: 26px;
  }
  .gel-item button {
    font-size: 20px;
  }
}
@media(max-width: 1000px) {
  
}
@media(max-width: 800px) {
  .since-bg-aloe-right {
    right: -80px;
  }
  .health-bg-aloe-right {
    top: 0;
  }
  .history-bg-aloe-left {
    bottom: -30px;
  }
}
@media(max-width: 732px) {
  .h2-aloe span {
    display: block;
  }
}
@media(max-width: 600px) {
  .health-col-bottom, .certified-col-bottom {
    padding-top: 0px;
    margin-top: -10px;
  }
  .gel-item img, .certificate-item img, .since-item img, .person-img img, .history-img {
    width: auto;
  }
  .since-bg-aloe-left {
    bottom: -20px;
  }
  .history-item-text {
    max-width: 350px;
    margin:0 auto;
  }
  .history-bg-aloe-left {
    bottom: -20px;
  }
  .video-box, .video-main-box, .video-box-2 {
    padding: 15px;
  }
  .aloe-footer .title {
    font-size: 10px !important;
  }
  .p-title-since.zaeb {
    text-align: justify;
  }
}
@media(max-width: 480px) {
  .main-bg-aloe-right-bottom {
    bottom: 15%;
  }
  .video-box, .video-main-box, .video-box-2 {
    padding: 10px;
  }
}
@media(max-width: 413px) {
  .h3-aloe span {
    display: block;
  }
}
@media(max-width: 360px) {
  
}
</style>